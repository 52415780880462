import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const AdminCreateGroup = ({ show, onClose, onGroupCreated }) => {
  const [groupName, setGroupName] = useState('')
  const [status, setStatus] = useState('active')

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await fetch('/api/v1/admin/group/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          groupName,
          status: status === 'active' ? 1 : 0,
        }),
      })

      if (response.ok) {
        const data = await response.json()
        const newGroup = {
          _id: data.newGroup._id,
          groupName: data.newGroup.groupName,
          status: data.newGroup.status,
        }

        onGroupCreated(newGroup)
        setGroupName('')
        setStatus('active')
        onClose()
      } else {
        const errorData = await response.json()
        toast.error(`Error: ${errorData.error}`)
      }
    } catch (error) {
      toast.error('An unexpected error occurred')
    }
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className='fs-5'>Create New Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className='mb-3'>
            <label htmlFor='groupName' className='form-label'>
              Group Name
            </label>
            <input
              type='text'
              className='form-control'
              id='groupName'
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              required
            />
          </div>
          <div className='mb-3'>
            <label className='form-label'>Status</label>
            <div className='d-flex align-items-center'>
              <div className='form-check me-3'>
                <input
                  className='form-check-input'
                  type='radio'
                  id='activeStatus'
                  name='status'
                  value='active'
                  checked={status === 'active'}
                  onChange={(e) => setStatus(e.target.value)}
                />
                <label className='form-check-label' htmlFor='activeStatus'>
                  Active
                </label>
              </div>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  id='inactiveStatus'
                  name='status'
                  value='inactive'
                  checked={status === 'inactive'}
                  onChange={(e) => setStatus(e.target.value)}
                />
                <label className='form-check-label' htmlFor='inactiveStatus'>
                  Inactive
                </label>
              </div>
            </div>
          </div>
          <div className='text-end'>
            <button type='submit' className='btn btn-primary me-3'>
              Save
            </button>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default AdminCreateGroup
