import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import axios from 'axios'
import { toast } from 'react-toastify'

const AdminAddBook = ({ show, onClose, onAddBook }) => {
  const [formData, setFormData] = useState({
    itemCode: '',
    description: '',
    price: '',
    itemType: '',
    barCode: '',
    subsidized: '0',
    selectForStat: '0',
  })

  const [categories, setCategories] = useState([])
  const [isCategoryLoading, setIsCategoryLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleCategoryFocus = async () => {
    if (categories.length === 0) {
      setIsCategoryLoading(true)
      try {
        const { data } = await axios.get(
          '/api/v1/admin/bookstock/search-category-add'
        )
        setCategories(data)
      } catch (error) {
        console.error('Error fetching categories:', error)
        alert('Failed to load categories.')
      } finally {
        setIsCategoryLoading(false)
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    try {
      const response = await axios.post('/api/v1/admin/bookstock/add', formData)
      if (response.data?.newStockItem) {
        const newBook = response.data.newStockItem
        toast.success('Book added successfully!')
        onAddBook(newBook)

        setFormData({
          itemCode: '',
          description: '',
          price: '',
          itemType: '',
          barCode: '',
          subsidized: '0',
          selectForStat: '0',
        })

        onClose()
      } else {
        toast.error('Failed to add the book.')
        console.error('Invalid response:', response.data)
      }
    } catch (error) {
      console.error('Error adding book:', error)
      toast.error('Failed to add the book. Please try again.')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className='fs-6'>Add New Book</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId='itemCode'>
            <Form.Label>Item Code</Form.Label>
            <Form.Control
              type='text'
              name='itemCode'
              value={formData.itemCode}
              onChange={handleChange}
              placeholder='Enter item code'
              required
            />
          </Form.Group>

          <Form.Group controlId='description' className='mt-3'>
            <Form.Label>Desc</Form.Label>
            <Form.Control
              type='text'
              name='description'
              value={formData.description}
              onChange={handleChange}
              placeholder='Enter description'
              required
            />
          </Form.Group>

          <Form.Group controlId='price' className='mt-3'>
            <Form.Label>Price</Form.Label>
            <Form.Control
              type='number'
              name='price'
              value={formData.price}
              onChange={handleChange}
              placeholder='Enter price'
              required
            />
          </Form.Group>

          <Form.Group controlId='itemType' className='mt-3'>
            <Form.Label>Category</Form.Label>
            <Form.Select
              name='itemType'
              value={formData.itemType}
              onChange={handleChange}
              onFocus={handleCategoryFocus}
              required
            >
              <option value=''>Select a category</option>
              {isCategoryLoading ? (
                <option value='' disabled>
                  Loading...
                </option>
              ) : (
                categories.map((category) => (
                  <option key={category._id} value={category.categoryName}>
                    {category.categoryName}
                  </option>
                ))
              )}
            </Form.Select>
          </Form.Group>

          <Form.Group controlId='barCode' className='mt-3'>
            <Form.Label>Barcode</Form.Label>
            <Form.Control
              type='text'
              name='barCode'
              value={formData.barCode}
              onChange={handleChange}
              placeholder='Enter barcode'
            />
          </Form.Group>

          <Form.Group controlId='subsidized' className='mt-3'>
            <Form.Label className='d-block'>Subsidi</Form.Label>
            <div className='d-flex align-items-center'>
              <Form.Check
                type='radio'
                id='subsidized-yes'
                name='subsidized'
                value='1'
                label='Yes'
                checked={formData.subsidized === '1'}
                onChange={handleChange}
                inline
              />
              <Form.Check
                type='radio'
                id='subsidized-no'
                name='subsidized'
                value='0'
                label='No'
                checked={formData.subsidized === '0'}
                onChange={handleChange}
                inline
              />
            </div>
          </Form.Group>

          <Form.Group controlId='selectForStat' className='mt-3'>
            <Form.Label className='d-block'>Statistic</Form.Label>
            <div className='d-flex align-items-center'>
              <Form.Check
                type='radio'
                id='stat-yes'
                name='selectForStat'
                value='1'
                label='Yes'
                checked={formData.selectForStat === '1'}
                onChange={handleChange}
                inline
              />
              <Form.Check
                type='radio'
                id='stat-no'
                name='selectForStat'
                value='0'
                label='No'
                checked={formData.selectForStat === '0'}
                onChange={handleChange}
                inline
              />
            </div>
          </Form.Group>

          <div className='mt-4 text-end'>
            <Button
              variant='primary'
              type='submit'
              disabled={isSubmitting}
              className='me-4 btn-sm'
            >
              {isSubmitting ? 'Saving...' : 'Save'}
            </Button>
            <Button variant='secondary' onClick={onClose} className='btn-sm'>
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default AdminAddBook
