import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'

const StatisticList = () => {
  const navigate = useNavigate()
  const [lists, setLists] = useState([])

  useEffect(() => {
    const fetchList = async () => {
      try {
        const { data } = await axios.get('/api/v1/user/statistic/lists')
        setLists(data)
      } catch (error) {
        console.log('Failed to fetch lists:', error)
      }
    }

    fetchList()
  }, [])

  const handleNewSale = () => {
    navigate('new')
  }

  const handleReturnHome = () => {
    navigate('/user/dashboard')
  }

  const handleDelete = async (id) => {
    if (window.confirm('Confiirm delete?')) {
      try {
        await axios.delete(`/api/v1/user/statistic/delete/${id}`)
        setLists((prevLists) => prevLists.filter((item) => item._id !== id))
        toast.success('Deleted successfully!')
      } catch (error) {
        console.error('Failed to delete record:', error)
        toast.error('Failed to delete the record. Please try again.')
      }
    }
  }

  return (
    <Layout title={'Statistic'}>
      <div className='pt-3 mx-3'>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <h5 className='mb-0' style={{ color: '#556B2F' }}>
            Statistics
          </h5>
          <div>
            <button
              type='button'
              className='btn btn-outline-success btn-sm me-4'
              onClick={handleNewSale}
            >
              <i className='bi bi-plus-circle'></i> Statistic
            </button>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm'
              onClick={handleReturnHome}
            >
              <i className='bi bi-house-fill'></i>
            </button>
          </div>
        </div>

        <table className='table'>
          <thead>
            <tr>
              <th
                scope='col'
                rowSpan='2'
                style={{
                  verticalAlign: 'middle',
                }}
              >
                Date
              </th>

              <th scope='col' colSpan='2' className='text-center'>
                Non-Christian (P/U)
              </th>
              <th
                scope='col'
                className='text-center'
                rowSpan='2'
                style={{
                  verticalAlign: 'middle',
                }}
              >
                Christian (P/U)
                <br />
                (Seeking)
              </th>
              <th
                scope='col'
                className='text-center'
                rowSpan='2'
                style={{
                  verticalAlign: 'middle',
                }}
              >
                Home Meeting (P/U)
              </th>
              <th
                scope='col'
                className='text-center'
                rowSpan='2'
                style={{
                  verticalAlign: 'middle',
                }}
              >
                Baptism
              </th>
              <th scope='col' className='text-center' rowSpan='2'></th>
            </tr>
            <tr>
              <th
                scope='col'
                className='text-center'
                style={{
                  borderTop: '1px solid #000',
                  borderRight: '1px solid #000',
                }}
              >
                Very Open
              </th>
              <th
                scope='col'
                className='text-center'
                style={{ borderTop: '1px solid #000' }}
              >
                Open
              </th>
            </tr>
          </thead>
          <tbody>
            {lists.length > 0 ? (
              lists.map((item) => (
                <tr key={item._id}>
                  <td>
                    {new Date(item.recordDate).toLocaleDateString('en-GB', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })}
                  </td>
                  <td className='text-center'>
                    {(item.nonChristianVOP > 0 || item.nonChristianVOU > 0) && (
                      <>
                        {item.nonChristianVOP}/{item.nonChristianVOU}
                      </>
                    )}
                  </td>
                  <td className='text-center'>
                    {(item.nonChristianOP > 0 || item.nonChristianOU > 0) && (
                      <>
                        {item.nonChristianOP}/{item.nonChristianOU}
                      </>
                    )}
                  </td>
                  <td className='text-center'>
                    {(item.christianP > 0 || item.christianU > 0) && (
                      <>
                        {item.christianP}/{item.christianU}
                      </>
                    )}
                  </td>
                  <td className='text-center'>
                    {(item.homeP > 0 || item.homeU > 0) && (
                      <>
                        {item.homeP}/{item.homeU}
                      </>
                    )}
                  </td>
                  <td className='text-center'>
                    {item.baptism > 0 && item.baptism}
                  </td>
                  <td className='text-end'>
                    <button
                      type='button'
                      className='btn btn-outline-primary btn-sm me-3'
                      onClick={() =>
                        navigate(`/user/statistics/edit/${item._id}`)
                      }
                    >
                      <i className='bi bi-pencil-square'></i>
                    </button>

                    <button
                      type='button'
                      className='btn btn-outline-danger btn-sm'
                      onClick={() => handleDelete(item._id)}
                    >
                      <i className='bi bi-trash-fill'></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan='9' className='text-center'>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default StatisticList
