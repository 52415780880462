import React, { useState, useEffect } from 'react'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import { Dropdown } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ResponseCardEdit = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    phone: '',
    race: '',
    gender: null,
    language: null,
    otherLang: '',
    categoryOne: null,
    categoryTwo: null,
    remark: '',
    responseOption: [],
    selectedBooks: [],
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [packingListData, setPackingListData] = useState([])

  useEffect(() => {
    const fetchResponseCard = async () => {
      try {
        const { data } = await axios.get(`/api/v1/user/response/${id}`)
        setFormData({
          ...data,
          selectedBooks: data.bookRecord || [],
        })
      } catch (error) {
        console.error('Failed to fetch response card:', error)
        toast.error('Failed to fetch response card.')
      }
    }

    fetchResponseCard()
  }, [id])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleCheckboxChange = (e) => {
    const value = parseInt(e.target.value, 10)
    setFormData((prev) => ({
      ...prev,
      responseOption: e.target.checked
        ? [...prev.responseOption, value]
        : prev.responseOption.filter((opt) => opt !== value),
    }))
  }

  const handleSearch = async (e) => {
    const term = e.target.value
    setSearchTerm(term)

    if (term.length > 3) {
      try {
        const { data } = await axios.get('/api/v1/user/response/search-book', {
          params: { search: term },
        })
        setPackingListData(data)
      } catch (error) {
        console.error(
          'Failed to fetch packing list data:',
          error.response?.data?.message || error.message
        )
      }
    } else {
      setPackingListData([])
    }
  }

  const handleSelect = (item) => {
    setFormData((prev) => ({
      ...prev,
      selectedBooks: [...prev.selectedBooks, { ...item, qty: 1 }],
    }))
    setSearchTerm('')
    setPackingListData([])
  }

  const handleQtyChange = (index, value) => {
    const updatedBooks = [...formData.selectedBooks]
    updatedBooks[index].qty = value
    setFormData((prev) => ({
      ...prev,
      selectedBooks: updatedBooks,
    }))
  }

  const handleRadioChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (
      !formData.name ||
      !formData.gender ||
      !formData.race ||
      !formData.language ||
      !formData.categoryOne ||
      !formData.categoryTwo
    ) {
      toast.error('* are required fields.')
      return
    }

    const updatedData = {
      ...formData,
      bookRecord: formData.selectedBooks.map((book) => ({
        packingListData: book._id,
        itemCode: book.itemCode,
        description: book.description,
        price: book.price,
        qty: book.qty,
      })),
    }

    try {
      await axios.put(`/api/v1/user/response/update/${id}`, updatedData)
      toast.success('Response card updated successfully!')
      navigate('/user/respons')
    } catch (error) {
      console.error(
        'Failed to update response card:',
        error.response?.data || error.message
      )
      toast.error('Failed to update response card. Please try again.')
    }
  }

  const handleRemoveRow = (indexToRemove) => {
    setFormData((prev) => ({
      ...prev,
      selectedBooks: prev.selectedBooks.filter(
        (_, index) => index !== indexToRemove
      ),
    }))
  }

  const handleReturnHome = () => {
    navigate('/user/dashboard')
  }

  const handleCancel = () => {
    navigate('/user/respons')
  }

  return (
    <Layout title={'Edit Kad Respon'}>
      <div className='container pt-4 mb-5'>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <h5 className='mb-0' style={{ color: '#556B2F' }}>
            Edit Kad Respon
          </h5>
          <div>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm me-4'
              onClick={handleCancel}
            >
              Kad Respons Record
            </button>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm'
              onClick={handleReturnHome}
            >
              <i className='bi bi-house-fill'></i>
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className='mb-2'>
            Bagaimanakah anda ingin lebih mengenali sabda Tuhan (sila tandakan):
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
            {[
              { label: 'Kunjungan Rumah', value: 1 },
              { label: 'Pameran Buku', value: 2 },
              { label: 'Persidangan Khas', value: 3 },
              { label: 'Tempahan Buku', value: 4 },
            ].map((option) => (
              <label
                key={option.value}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.5rem',
                }}
              >
                <input
                  type='checkbox'
                  value={option.value}
                  checked={formData.responseOption.includes(option.value)}
                  onChange={handleCheckboxChange}
                  style={{ width: '20px', height: '20px' }}
                />
                {option.label}
              </label>
            ))}
          </div>
          <div className='my-3'>
            <label htmlFor='name' className='form-label'>
              Nama<span style={{ color: 'red' }}>*</span> :
            </label>
            <input
              type='text'
              id='name'
              name='name'
              className='form-control'
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='address' className='form-label'>
              Alamat:
            </label>
            <textarea
              id='address'
              name='address'
              className='form-control'
              rows='1'
              value={formData.address}
              onChange={handleInputChange}
            ></textarea>
          </div>

          <div className='mb-3'>
            <label htmlFor='phone' className='form-label'>
              No. Tel:
            </label>
            <input
              type='text'
              id='phone'
              className='form-control'
              value={formData.phone}
              onChange={handleInputChange}
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='race' className='form-label'>
              Bangsa<span style={{ color: 'red' }}>*</span> :
            </label>
            <input
              type='text'
              id='race'
              className='form-control'
              value={formData.race}
              onChange={handleInputChange}
            />
          </div>

          <div
            className='mb-3'
            style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
          >
            <label className='form-label' style={{ margin: 0 }}>
              Jantina<span style={{ color: 'red' }}>*</span> :
            </label>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <label>
                <input
                  type='radio'
                  name='gender'
                  value='1'
                  checked={formData.gender === 1}
                  onChange={() => handleRadioChange('gender', 1)}
                  style={{ marginRight: '0.5rem' }}
                />
                Male
              </label>
              <label>
                <input
                  type='radio'
                  name='gender'
                  value='2'
                  checked={formData.gender === 2}
                  onChange={() => handleRadioChange('gender', 2)}
                  style={{ marginRight: '0.5rem' }}
                />
                Female
              </label>
            </div>
          </div>

          <div
            className='mb-3'
            style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
          >
            <label className='form-label' style={{ margin: 0 }}>
              Bahasa<span style={{ color: 'red' }}>*</span> :
            </label>
            <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
              {[
                { label: 'Bahasa Melayu', value: 1 },
                { label: 'Chinese', value: 2 },
                { label: 'English', value: 3 },
                { label: 'Other', value: 4 },
              ].map((lang) => (
                <label key={lang.value}>
                  <input
                    type='radio'
                    name='language'
                    value={lang.value}
                    checked={formData.language === lang.value}
                    onChange={() => handleRadioChange('language', lang.value)}
                    style={{ marginRight: '0.5rem' }}
                  />
                  {lang.label}
                </label>
              ))}
            </div>
          </div>
          {formData.language === 4 && (
            <div className='mb-3'>
              <label htmlFor='otherLang' className='form-label'>
                Specify:
              </label>
              <input
                type='text'
                id='otherLang'
                className='form-control'
                value={formData.otherLang}
                onChange={handleInputChange}
              />
            </div>
          )}

          <div className='mb-3'>
            <label className='form-label'>
              Category I<span style={{ color: 'red' }}>*</span> :
            </label>
            <div style={{ display: 'flex', gap: '1rem' }}>
              {[
                { label: 'Adult', value: 1 },
                { label: 'Campus', value: 2 },
                { label: 'Youth', value: 3 },
                { label: 'Children', value: 4 },
              ].map((cat) => (
                <label key={cat.value}>
                  <input
                    type='radio'
                    name='categoryOne'
                    value={cat.value}
                    checked={formData.categoryOne === cat.value}
                    onChange={() => handleRadioChange('categoryOne', cat.value)}
                    style={{ marginRight: '0.5rem' }}
                  />
                  {cat.label}
                </label>
              ))}
            </div>
          </div>

          <div className='mb-3'>
            <label className='form-label'>
              Category II<span style={{ color: 'red' }}>*</span> :
            </label>
            <div style={{ display: 'flex', gap: '1rem' }}>
              {[
                { label: 'Saint', value: 1 },
                { label: 'Seeking Christian', value: 2 },
                { label: 'Gospel Friend', value: 3 },
                { label: 'New Believer', value: 4 },
                { label: 'Elderly', value: 5 },
              ].map((cat) => (
                <label key={cat.value}>
                  <input
                    type='radio'
                    name='categoryTwo'
                    value={cat.value}
                    checked={formData.categoryTwo === cat.value}
                    onChange={() => handleRadioChange('categoryTwo', cat.value)}
                    style={{ marginRight: '0.5rem' }}
                  />
                  {cat.label}
                </label>
              ))}
            </div>
          </div>

          <div className='mb-3'>
            <label htmlFor='remark' className='form-label'>
              Catatan:
            </label>
            <textarea
              id='remark'
              name='remark'
              className='form-control'
              value={formData.remark}
              onChange={handleInputChange}
            ></textarea>
          </div>

          <div className='mb-3'>
            <label htmlFor='bookSearch' className='form-label'>
              Buku:
            </label>
            <Dropdown
              show={packingListData.length > 0 && searchTerm.length > 3}
            >
              <Dropdown.Toggle
                as='input'
                type='text'
                id='bookSearch'
                className='form-control'
                placeholder='Search by Item Code or Book name'
                value={searchTerm}
                onChange={handleSearch}
                autoComplete='off'
              />
              <Dropdown.Menu style={{ width: '100%' }}>
                {packingListData.map((item) => {
                  const isSelected = formData.selectedBooks.some(
                    (book) => book.itemCode === item.itemCode
                  )
                  return (
                    <Dropdown.Item
                      key={item._id}
                      onClick={() => !isSelected && handleSelect(item)}
                      disabled={isSelected}
                      style={{
                        cursor: isSelected ? 'not-allowed' : 'pointer',
                        color: isSelected ? 'gray' : 'inherit',
                      }}
                    >
                      {item.itemCode} - {item.description}
                    </Dropdown.Item>
                  )
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className='mb-3'>
            <label htmlFor='bookSearch' className='form-label'>
              Selected Books:
            </label>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th>Buku</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {formData.selectedBooks.length > 0 ? (
                  formData.selectedBooks.map((book, index) => (
                    <tr key={index}>
                      <td>
                        {book.itemCode} {book.description}
                      </td>
                      <td className='text-end'>{book.price.toFixed(2)}</td>
                      <td>
                        <input
                          type='number'
                          min='1'
                          value={book.qty}
                          onFocus={(e) => e.target.select()}
                          onChange={(e) =>
                            handleQtyChange(index, e.target.value)
                          }
                          className='form-control'
                        />
                      </td>
                      <td className='text-center'>
                        <i
                          className='bi bi-trash3'
                          style={{ color: '#dc3545', cursor: 'pointer' }}
                          onClick={() => handleRemoveRow(index)}
                        ></i>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan='4' className='text-center'>
                      No books selected.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className='text-end pt-3'>
            <button
              type='submit'
              className='btn btn-outline-warning btn-sm me-4'
            >
              Update
            </button>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm'
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default ResponseCardEdit
