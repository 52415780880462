import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Table, Spinner } from 'react-bootstrap'
import axios from 'axios'

const AdminBookCategory = ({ show, onClose }) => {
  const [categoryName, setCategoryName] = useState('')
  const [categories, setCategories] = useState([])
  const [editingRowIndex, setEditingRowIndex] = useState(null)
  const [editingCategoryName, setEditingCategoryName] = useState('')
  const [originalCategoryName, setOriginalCategoryName] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (show) {
      fetchCategories()
    }
  }, [show])

  const fetchCategories = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get('/api/v1/admin/bookstock/get-categories')
      setCategories(response.data.categories || [])
    } catch (error) {
      console.error('Error fetching categories:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    try {
      const response = await axios.post(
        '/api/v1/admin/bookstock/add-category',
        { categoryName }
      )
      setCategories((prevCategories) => [
        ...prevCategories,
        response.data.category,
      ])
      setCategoryName('')
    } catch (error) {
      console.error('Error adding category:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleEditCategory = async (categoryId, newCategoryName) => {
    if (newCategoryName.trim() === originalCategoryName.trim()) {
      setEditingRowIndex(null)
      return
    }

    try {
      const response = await axios.put(
        `/api/v1/admin/bookstock/update-category/${categoryId}`,
        { categoryName: newCategoryName }
      )
      setCategories((prevCategories) =>
        prevCategories.map((category) =>
          category._id === categoryId
            ? { ...category, categoryName: response.data.category.categoryName }
            : category
        )
      )
    } catch (error) {
      console.error('Error updating category:', error)
    } finally {
      setEditingRowIndex(null)
    }
  }

  const handleDoubleClick = (index, category) => {
    setEditingRowIndex(index)
    setEditingCategoryName(category.categoryName)
    setOriginalCategoryName(category.categoryName)
  }

  const handleToggleStatus = async (category) => {
    const newStatus = category.status === 1 ? 0 : 1

    try {
      const response = await axios.put(
        `/api/v1/admin/bookstock/update-category-status/${category._id}`,
        { status: newStatus }
      )
      setCategories((prevCategories) =>
        prevCategories.map((cat) =>
          cat._id === category._id
            ? { ...cat, status: response.data.category.status }
            : cat
        )
      )
    } catch (error) {
      console.error('Error updating status:', error)
    }
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className='fs-5'>Book Categories</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={handleSubmit}
          className='d-flex justify-content-between mb-4'
        >
          <Form.Group controlId='categoryName' className='flex-grow-1 me-3'>
            <Form.Control
              type='text'
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              placeholder='Enter Category Name'
              autoComplete='off'
              required
            />
          </Form.Group>
          <Button
            variant='primary'
            type='submit'
            disabled={isSubmitting}
            className='align-self-center'
          >
            {isSubmitting ? 'Saving...' : 'Save'}
          </Button>
        </Form>

        <Table bordered responsive>
          <thead>
            <tr>
              <th></th>
              <th>Category</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan='3' className='text-center'>
                  <Spinner animation='border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </Spinner>
                </td>
              </tr>
            ) : categories.length > 0 ? (
              categories.map((category, index) => (
                <tr key={category._id}>
                  <td>{index + 1}</td>
                  <td onDoubleClick={() => handleDoubleClick(index, category)}>
                    {editingRowIndex === index ? (
                      <Form.Control
                        type='text'
                        value={editingCategoryName}
                        onChange={(e) => setEditingCategoryName(e.target.value)}
                        onBlur={() =>
                          handleEditCategory(category._id, editingCategoryName)
                        }
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleEditCategory(
                              category._id,
                              editingCategoryName
                            )
                          }
                        }}
                        autoFocus
                      />
                    ) : (
                      category.categoryName
                    )}
                  </td>
                  <td
                    style={{
                      color: category.status === 1 ? 'green' : 'grey',
                      cursor: 'pointer',
                    }}
                    onDoubleClick={() => handleToggleStatus(category)}
                  >
                    {category.status === 1 ? 'Active' : 'Inactive'}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan='3' className='text-center'>
                  No categories available.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  )
}

export default AdminBookCategory
