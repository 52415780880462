import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'antd'
import axios from 'axios'
import { useAuth } from '../../../context/auth'

const AdminPackingListIndex = () => {
  const navigate = useNavigate()

  const handleReturnHome = () => {
    navigate('/admin/dashboard')
  }

  return (
    <Layout title={'Packing List'}>
      <div className='pt-3 mx-3'>
        <div className='text-end mb-3'>
          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={handleReturnHome}
          >
            <i className='bi bi-house-fill'></i>
          </button>
        </div>
      </div>
    </Layout>
  )
}

export default AdminPackingListIndex
