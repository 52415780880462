import React, { useState, useEffect, useRef, useCallback } from 'react'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import AdminAddBook from './AddBook'
import AdminBookCategory from './BookCategory'
import AdminEditBook from './EditBook'
import { useNavigate } from 'react-router-dom'

const AdminBookStockList = () => {
  const navigate = useNavigate()

  const [items, setItems] = useState([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [searchData, setSearchData] = useState('')
  const [showAddBookModal, setShowAddBookModal] = useState(false)
  const [showEditBookModal, setShowEditBookModal] = useState(false)
  const [editBookData, setEditBookData] = useState(null)
  const [showCategoryModal, setShowCategoryModal] = useState(false)

  const observer = useRef()
  const lastElementRef = useCallback(
    (node) => {
      if (isFetching) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && !searchData) {
          loadMoreItems()
        }
      })

      if (node) observer.current.observe(node)
    },
    [isFetching, hasMore, searchData]
  )

  const loadMoreItems = async () => {
    if (isFetching || !hasMore) return
    setIsFetching(true)

    try {
      const response = await axios.get('/api/v1/admin/bookstock/list', {
        params: { page, limit: 30 },
      })

      const newItems = Array.isArray(response.data.items)
        ? response.data.items
        : []
      setItems((prev) => [...prev, ...newItems])
      setPage((prevPage) => prevPage + 1)
      setHasMore(response.data.pagination.hasNextPage)
    } catch (error) {
      console.error('Error loading more items:', error)
    } finally {
      setIsFetching(false)
    }
  }

  const searchProductResult = async () => {
    if (searchData.length < 4) return
    try {
      const response = await axios.post('/api/v1/admin/bookstock/search', {
        searchData,
      })
      const newItems = Array.isArray(response.data.items)
        ? response.data.items
        : []
      setItems(newItems)
      setHasMore(false)
    } catch (error) {
      console.error('Error searching items:', error)
    }
  }

  const handleClearSearch = () => {
    setSearchData('')
    setPage(1)
    setItems([])
    setHasMore(true)
    loadMoreItems()
  }

  const getSearchContent = (value) => {
    setSearchData(value)
    if (!value.length) {
      setPage(1)
      setItems([])
      setHasMore(true)
      loadMoreItems()
    }
  }

  useEffect(() => {
    if (searchData.length > 3) {
      searchProductResult()
    } else if (searchData.length === 0) {
      setItems([])
      setPage(1)
      setHasMore(true)
      loadMoreItems()
    }
  }, [searchData])

  useEffect(() => {
    loadMoreItems()
  }, [])

  const handleRemoveItems = async () => {
    try {
      await axios.delete('/api/v1/admin/bookstock/remove-item')
      setPage(1)
      setItems([])
      setHasMore(true)
      loadMoreItems()
    } catch (error) {
      console.error('Error removing items:', error)
    }
  }

  const handleAddBook = () => {
    setShowAddBookModal(true)
  }

  const handleCloseAddBookModal = () => {
    setShowAddBookModal(false)
  }

  const handleAddBookToList = (newBook) => {
    setItems((prevItems) => [newBook, ...prevItems])
  }

  const handleCategory = () => {
    setShowCategoryModal(true)
  }

  const handleCloseCategoryModal = () => {
    setShowCategoryModal(false)
  }

  const handleEditBook = (bookId) => {
    setEditBookData(bookId)
    setShowEditBookModal(true)
  }

  const handleCloseEditBookModal = () => {
    setShowEditBookModal(false)
    setEditBookData(null)
  }

  const handleUpdateBookInList = (updatedBook) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item._id === updatedBook._id ? updatedBook : item
      )
    )
  }

  const handleReturnHome = () => {
    navigate('/admin/dashboard')
  }

  return (
    <Layout title={'Book Stock Record'}>
      <div
        className='searchInventory shadow-sm p-3 bg-white rounded'
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: '65px',
          position: 'fixed',
          zIndex: 1000,
        }}
      >
        <div className='col-auto' style={{ width: '45%', display: 'flex' }}>
          <input
            type='text'
            className='form-control'
            value={searchData}
            placeholder='Search Item Code or Item Name'
            onChange={(e) => getSearchContent(e.target.value)}
            style={{ flex: 1 }}
          />
          <button
            type='button'
            className='btn btn-outline-secondary'
            disabled={!searchData}
            onClick={handleClearSearch}
          >
            Clear
          </button>
        </div>

        {/* <div
          className='col-auto text-end mx-3'
          style={{
            width: '5%',
            marginTop: '0',
          }}
        >
          <button
            type='button'
            className='btn btn-danger btn-sm'
            onClick={handleRemoveItems}
          >
            Removed
          </button>
        </div> */}

        <div
          className='col-auto text-end mx-3'
          style={{
            width: '10%',
            marginTop: '0',
          }}
        >
          <button
            type='button'
            className='btn btn-outline-success btn-sm'
            onClick={handleAddBook}
          >
            <i className='bi bi-plus-circle'></i> Book
          </button>
        </div>
        <div
          className='col-auto text-end mx-3'
          style={{
            width: '10%',
            marginTop: '0',
          }}
        >
          <button
            type='button'
            className='btn btn-outline-info btn-sm'
            onClick={handleCategory}
          >
            Category
          </button>
        </div>

        <div
          className='col-auto text-end'
          style={{
            width: '5%',
            marginTop: '0',
          }}
        >
          <button
            type='button'
            className='btn btn-primary btn-sm'
            onClick={() => window.scrollTo(0, 0)}
          >
            <i className='bi bi-arrow-up-circle'></i>
          </button>
        </div>
        <div
          className='col-auto text-end mx-3'
          style={{
            width: '10%',
            marginTop: '0',
          }}
        >
          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={handleReturnHome}
          >
            <i className='bi bi-house-fill'></i>
          </button>
        </div>
      </div>

      <div>
        <div className='table-responsive'>
          <table className='table actable' style={{ marginTop: '80px' }}>
            <thead>
              <tr>
                <th>Item Code</th>
                <th>Desc</th>
                <th className='text-end'>Price</th>
                <th>Category</th>
                <th>Barcode</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr
                  key={index}
                  ref={
                    index === items.length - 1 && hasMore && !searchData
                      ? lastElementRef
                      : null
                  }
                >
                  <td>{item.itemCode}</td>
                  <td
                    style={{
                      fontWeight:
                        item.subsidized === 1 || item.selectForStat === 1
                          ? 'bold'
                          : 'normal',
                      fontStyle: item.selectForStat === 1 ? 'italic' : 'normal',
                      color:
                        item.subsidized === 1 || item.selectForStat === 1
                          ? '#556B2F'
                          : 'inherit',
                    }}
                  >
                    {item.description}
                    {item.subsidized === 1 && ' (S)'}
                  </td>
                  <td className='text-end'>{item.price?.toFixed(2)}</td>
                  <td>{item.itemType}</td>
                  <td>{item.barCode}</td>
                  <td className='text-end'>
                    <button
                      type='button'
                      className='btn btn-outline-primary btn-sm'
                      onClick={() => handleEditBook(item._id)}
                    >
                      <i class='bi bi-pencil-square'></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='text-center'>
          {isFetching && <p>Loading items...</p>}
        </div>
      </div>

      <AdminAddBook
        show={showAddBookModal}
        onClose={handleCloseAddBookModal}
        onAddBook={handleAddBookToList}
      />
      <AdminEditBook
        show={showEditBookModal}
        onClose={handleCloseEditBookModal}
        bookId={editBookData} // Pass bookId
        onUpdateBook={handleUpdateBookInList}
      />
      <AdminBookCategory
        show={showCategoryModal}
        onClose={handleCloseCategoryModal}
      />
    </Layout>
  )
}

export default AdminBookStockList
