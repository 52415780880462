import React from 'react'
import Layout from '../../components/Layout/Layout'
import { Link } from 'react-router-dom'

const MainCoordinatorDashboard = () => {
  return (
    <Layout title={'Main Coordinator Dashboard'}>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/main/book-records'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Form 1 - Main Record
              </Link>
            </div>
          </div>

          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/main/places'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Places
              </Link>
            </div>
          </div>

          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to=''
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Sale Records
              </Link>
            </div>
          </div>

          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to=''
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Statistics
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default MainCoordinatorDashboard
