import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

const AdminEditTeam = ({ show, onClose, teamId, onTeamUpdated }) => {
  const [teamName, setTeamName] = useState('')
  const [status, setStatus] = useState('active')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (teamId) {
      const fetchTeamDetails = async () => {
        try {
          const response = await fetch(`/api/v1/admin/group/team/${teamId}`)
          if (response.ok) {
            const data = await response.json()
            setTeamName(data.teamName)
            setStatus(data.status === 1 ? 'active' : 'inactive')
          } else {
            toast.error('Failed to fetch team details')
          }
        } catch (error) {
          toast.error('An unexpected error occurred')
        }
      }
      fetchTeamDetails()
    }
  }, [teamId])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      const response = await fetch(
        `/api/v1/admin/group/team/update/${teamId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            teamName,
            status: status === 'active' ? 1 : 2,
          }),
        }
      )

      if (response.ok) {
        const updatedTeam = await response.json()
        if (onTeamUpdated) onTeamUpdated(updatedTeam) // Pass updated team data
        onClose()
      } else {
        toast.error('Failed to update team')
      }
    } catch (error) {
      toast.error('An unexpected error occurred')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Team</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className='mb-3'>
            <label htmlFor='teamName' className='form-label'>
              Team Name
            </label>
            <input
              type='text'
              className='form-control'
              id='teamName'
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
              required
              disabled={loading}
            />
          </div>
          <div className='mb-3'>
            <label className='form-label'>Status</label>
            <div className='d-flex align-items-center'>
              <div className='form-check me-3'>
                <input
                  className='form-check-input'
                  type='radio'
                  id='activeStatus'
                  name='status'
                  value='active'
                  checked={status === 'active'}
                  onChange={(e) => setStatus(e.target.value)}
                  disabled={loading}
                />
                <label className='form-check-label' htmlFor='activeStatus'>
                  Active
                </label>
              </div>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  id='inactiveStatus'
                  name='status'
                  value='inactive'
                  checked={status === 'inactive'}
                  onChange={(e) => setStatus(e.target.value)}
                  disabled={loading}
                />
                <label className='form-check-label' htmlFor='inactiveStatus'>
                  Inactive
                </label>
              </div>
            </div>
          </div>
          <div className='text-end'>
            <button
              type='submit'
              className='btn btn-primary me-3'
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={onClose}
              disabled={loading}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default AdminEditTeam
