import React from 'react'
import AdminHeader from './AdminHeader'
import { useAuth } from '../../context/auth'
import { Helmet } from 'react-helmet'
import UserHeader from './UserHeader'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import MainHeader from './MainHeader'

const Layout = ({ children, title, description, author }) => {
  const [auth] = useAuth()

  const renderHeader = () => {
    if (auth.user.role === 1) {
      return <AdminHeader />
    }
    if (auth.user.role === 2) {
      return <MainHeader />
    }
    if (auth.user.role === 3) {
      return <UserHeader />
    }
  }

  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <meta name='description' content={description} />
        <meta name='author' content={author} />
        <title>{title}</title>
      </Helmet>
      {renderHeader()}
      <main style={{ marginTop: '50px' }}>
        <ToastContainer
          position='top-center'
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          theme='light'
        />
        {children}
      </main>
    </div>
  )
}

export default Layout
