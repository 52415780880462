import React, { useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../context/auth'

const NewStatistic = () => {
  const navigate = useNavigate()
  const [auth] = useAuth()
  const userId = auth?.user?.id
  const groupId = auth?.user?.group
  const teamId = auth?.user?.team

  const today = new Date().toISOString().split('T')[0]
  const [recordDate, setRecordDate] = useState(today)

  const [nonChristianVOP, setNonChristianVOP] = useState(0)
  const [nonChristianVOU, setNonChristianVOU] = useState(0)
  const [nonChristianOP, setNonChristianOP] = useState(0)
  const [nonChristianOU, setNonChristianOU] = useState(0)
  const [christianP, setChristianP] = useState(0)
  const [christianU, setChristianU] = useState(0)
  const [homeP, setHomeP] = useState(0)
  const [homeU, setHomeU] = useState(0)
  const [baptism, setBaptism] = useState(0)

  const handleSubmit = async (e) => {
    e.preventDefault()

    const formData = {
      userId,
      groupId,
      teamId,
      recordDate,
      nonChristianVOP,
      nonChristianVOU,
      nonChristianOP,
      nonChristianOU,
      christianP,
      christianU,
      homeP,
      homeU,
      baptism,
    }

    try {
      await axios.post('/api/v1/user/statistic/add', formData)
      toast.success('Submitted successfully!')
      setRecordDate(today)
      setNonChristianVOP(0)
      setNonChristianVOU(0)
      setNonChristianOP(0)
      setNonChristianOU(0)
      setChristianP(0)
      setChristianU(0)
      setHomeP(0)
      setHomeU(0)
      setBaptism(0)
    } catch (error) {
      console.error(
        'Failed to submit record:',
        error.response?.data || error.message
      )
      toast.error('Failed to submit record. Please try again.')
    }
  }

  const handleCancel = () => {
    navigate('/user/statistics')
  }

  const handleReturnHome = () => {
    navigate('/user/dashboard')
  }

  return (
    <Layout title={'Add New Statistic'}>
      <div className='container pt-4 mb-5'>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <h5 className='mb-0' style={{ color: '#556B2F' }}>
            Add New Statistic
          </h5>
          <div>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm me-4'
              onClick={handleCancel}
            >
              Statistics Record
            </button>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm'
              onClick={handleReturnHome}
            >
              <i className='bi bi-house-fill'></i>
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className='mb-3'>
            <label htmlFor='recordDate' className='form-label'>
              Date:
            </label>
            <input
              type='date'
              id='recordDate'
              value={recordDate}
              onChange={(e) => setRecordDate(e.target.value)}
              className='form-control'
              required
            />
          </div>

          <div className='mb-3'>
            <label className='form-label'>
              Non-Christian (P/U) - Very Open
            </label>
            <div className='row g-3 align-items-center'>
              <div className='col-md-6 d-flex align-items-center'>
                <label htmlFor='nonChristianVOP' className='me-2'>
                  P:
                </label>
                <input
                  type='number'
                  id='nonChristianVOP'
                  value={nonChristianVOP}
                  onChange={(e) => setNonChristianVOP(Number(e.target.value))}
                  className='form-control'
                  min='0'
                  onFocus={(e) => e.target.select()}
                />
              </div>
              <div className='col-md-6 d-flex align-items-center'>
                <label htmlFor='nonChristianVOU' className='me-2'>
                  U:
                </label>
                <input
                  type='number'
                  id='nonChristianVOU'
                  value={nonChristianVOU}
                  onChange={(e) => setNonChristianVOU(Number(e.target.value))}
                  className='form-control'
                  min='0'
                  onFocus={(e) => e.target.select()}
                />
              </div>
            </div>
          </div>

          <div className='mb-3'>
            <label className='form-label'>Non-Christian (P/U) - Open</label>
            <div className='row g-3 align-items-center'>
              <div className='col-md-6 d-flex align-items-center'>
                <label htmlFor='nonChristianOP' className='me-2'>
                  P:
                </label>
                <input
                  type='number'
                  id='nonChristianOP'
                  value={nonChristianOP}
                  onChange={(e) => setNonChristianOP(Number(e.target.value))}
                  className='form-control'
                  min='0'
                  onFocus={(e) => e.target.select()}
                />
              </div>
              <div className='col-md-6 d-flex align-items-center'>
                <label htmlFor='nonChristianOU' className='me-2'>
                  U:
                </label>
                <input
                  type='number'
                  id='nonChristianOU'
                  value={nonChristianOU}
                  onChange={(e) => setNonChristianOU(Number(e.target.value))}
                  className='form-control'
                  min='0'
                  onFocus={(e) => e.target.select()}
                />
              </div>
            </div>
          </div>

          <div className='mb-3'>
            <label className='form-label'>Christian (P/U) - Seeking</label>
            <div className='row g-3 align-items-center'>
              <div className='col-md-6 d-flex align-items-center'>
                <label htmlFor='christianP' className='me-2'>
                  P:
                </label>
                <input
                  type='number'
                  id='christianP'
                  value={christianP}
                  onChange={(e) => setChristianP(Number(e.target.value))}
                  className='form-control'
                  min='0'
                  onFocus={(e) => e.target.select()}
                />
              </div>
              <div className='col-md-6 d-flex align-items-center'>
                <label htmlFor='christianU' className='me-2'>
                  U:
                </label>
                <input
                  type='number'
                  id='christianU'
                  value={christianU}
                  onChange={(e) => setChristianU(Number(e.target.value))}
                  className='form-control'
                  min='0'
                  onFocus={(e) => e.target.select()}
                />
              </div>
            </div>
          </div>

          <div className='mb-3'>
            <label className='form-label'>Home Meeting (P/U)</label>
            <div className='row g-3 align-items-center'>
              <div className='col-md-6 d-flex align-items-center'>
                <label htmlFor='homeP' className='me-2'>
                  P:
                </label>
                <input
                  type='number'
                  id='homeP'
                  value={homeP}
                  onChange={(e) => setHomeP(Number(e.target.value))}
                  className='form-control'
                  min='0'
                  onFocus={(e) => e.target.select()}
                />
              </div>
              <div className='col-md-6 d-flex align-items-center'>
                <label htmlFor='homeU' className='me-2'>
                  U:
                </label>
                <input
                  type='number'
                  id='homeU'
                  value={homeU}
                  onChange={(e) => setHomeU(Number(e.target.value))}
                  className='form-control'
                  min='0'
                  onFocus={(e) => e.target.select()}
                />
              </div>
            </div>
          </div>

          <div className='mb-3'>
            <label className='form-label'>Baptism</label>
            <div className='row g-3 align-items-center'>
              <div className='col-md-6 d-flex align-items-center'>
                <input
                  type='number'
                  id='baptism'
                  value={baptism}
                  onChange={(e) => setBaptism(Number(e.target.value))}
                  className='form-control'
                  min='0'
                  onFocus={(e) => e.target.select()}
                />
              </div>
            </div>
          </div>

          <div className='text-end'>
            <button
              type='submit'
              className='btn btn-outline-primary btn-sm me-4'
            >
              Submit
            </button>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm'
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default NewStatistic
