import React, { useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Dropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../context/auth'

const NewSale = () => {
  const navigate = useNavigate()
  const [auth] = useAuth()
  const userId = auth?.user?.id
  const groupId = auth?.user?.group
  const teamId = auth?.user?.team
  const [remark, setRemark] = useState('')

  const [searchTerm, setSearchTerm] = useState('')
  const [packingListData, setPackingListData] = useState([])
  const [selectedBooks, setSelectedBooks] = useState([])

  const today = new Date().toISOString().split('T')[0]
  const [recordDate, setRecordDate] = useState(today)

  const handleSearch = async (e) => {
    const term = e.target.value
    setSearchTerm(term)

    if (term.length > 3) {
      try {
        const { data } = await axios.get('/api/v1/user/response/search-book', {
          params: { search: term },
        })
        setPackingListData(data)
      } catch (error) {
        console.error(
          'Failed to fetch packing list data:',
          error.response?.data?.message || error.message
        )
      }
    } else {
      setPackingListData([])
    }
  }

  const handleSelect = (item) => {
    setSelectedBooks((prevBooks) => [...prevBooks, { ...item, qty: 1 }])
    setSearchTerm('')
    setPackingListData([])
  }

  const handleQtyChange = (index, value) => {
    const updatedBooks = [...selectedBooks]
    updatedBooks[index].qty = value
    setSelectedBooks(updatedBooks)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = {
      bookRecord: selectedBooks.map((book) => ({
        packingListData: book._id,
        itemCode: book.itemCode,
        description: book.description,
        price: book.price,
        qty: book.qty,
      })),
      userId: userId,
      groupId: groupId,
      teamId: teamId,
      recordDate,
    }

    try {
      await axios.post('/api/v1/user/sale-record/add', formData)
      toast.success('Submitted successfully!')
      setSelectedBooks([])
      setRecordDate(today)
    } catch (error) {
      console.error(
        'Failed to submit record:',
        error.response?.data || error.message
      )
      toast.error('Failed to submit record. Please try again.')
    }
  }

  const handleCancel = () => {
    navigate('/user/sales-record')
  }

  const handleReturnHome = () => {
    navigate('/user/dashboard')
  }

  return (
    <Layout title={'Jualan Buku Baru'}>
      <div className='container pt-4 mb-5'>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <h5 className='mb-0' style={{ color: '#556B2F' }}>
            Jualan Buku Baru
          </h5>
          <div>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm me-4'
              onClick={handleCancel}
            >
              Rekod Jualan Buku
            </button>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm'
              onClick={handleReturnHome}
            >
              <i className='bi bi-house-fill'></i>
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className='mb-3'>
            <label htmlFor='recordDate' className='form-label'>
              Tarikh:
            </label>
            <input
              type='date'
              id='recordDate'
              value={recordDate}
              onChange={(e) => setRecordDate(e.target.value)}
              className='form-control'
              required
            />
          </div>

          <div className='mb-3'>
            <label htmlFor='bookSearch' className='form-label'>
              Buku:
            </label>
            <Dropdown
              show={packingListData.length > 0 && searchTerm.length > 3}
            >
              <Dropdown.Toggle
                as='input'
                type='text'
                id='bookSearch'
                className='form-control'
                placeholder='Cari Item Code / Nama Buku'
                value={searchTerm}
                onChange={handleSearch}
                autoComplete='off'
              />
              <Dropdown.Menu style={{ width: '100%' }}>
                {packingListData.map((item) => {
                  const isSelected = selectedBooks.some(
                    (book) => book.itemCode === item.itemCode
                  )
                  return (
                    <Dropdown.Item
                      key={item._id}
                      onClick={() => !isSelected && handleSelect(item)}
                      disabled={isSelected}
                      style={{
                        cursor: isSelected ? 'not-allowed' : 'pointer',
                        color: isSelected ? 'gray' : 'inherit',
                      }}
                    >
                      {item.itemCode} - {item.description}
                    </Dropdown.Item>
                  )
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className='mb-3'>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th>Buku</th>
                  <th>Harga</th>
                  <th>Qty</th>
                </tr>
              </thead>
              <tbody>
                {selectedBooks.length > 0 ? (
                  selectedBooks.map((book, index) => (
                    <tr key={index}>
                      <td>
                        {book.itemCode} {book.description}
                      </td>
                      <td className='text-end'>{book.price.toFixed(2)}</td>
                      <td>
                        <input
                          type='number'
                          min='1'
                          value={book.qty}
                          onFocus={(e) => e.target.select()}
                          onChange={(e) =>
                            handleQtyChange(index, e.target.value)
                          }
                          className='form-control'
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan='4' className='text-center'>
                      Tiada buku dipilih
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className='mb-4'>
            <label htmlFor='remark' className='form-label'>
              Catatan:
            </label>
            <textarea
              id='remark'
              className='form-control'
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
            ></textarea>
          </div>

          <div className='text-end'>
            <button
              type='submit'
              className='btn btn-outline-primary btn-sm me-4'
            >
              Hantar
            </button>

            <button
              className='btn btn-outline-secondary btn-sm'
              onClick={handleCancel}
            >
              Batal
            </button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default NewSale
