import React, { useState, useEffect } from 'react'
import Layout from '../../../components/Layout/Layout'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import CreateNewPlace from './CreateNewPlace'
import axios from 'axios'
import EditPlace from './EditPlace'

const MainPlaceList = () => {
  const navigate = useNavigate()

  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [places, setPlaces] = useState([])
  const [loading, setLoading] = useState(true)
  const [placeToEdit, setPlaceToEdit] = useState(null)

  useEffect(() => {
    const fetchPlaces = async () => {
      try {
        const { data } = await axios.get('/api/v1/main/place/list')
        setPlaces(data)
        setLoading(false)
      } catch (error) {
        console.error('Failed to fetch places:', error)
        setLoading(false)
      }
    }

    fetchPlaces()
  }, [])

  const handleShowCreateModal = () => {
    setShowCreateModal(true)
  }

  const handleCloseCreateModal = () => {
    setShowCreateModal(false)
  }

  const handleAddPlace = (newPlace) => {
    setPlaces((prevPlaces) => {
      const categoryIndex = prevPlaces.findIndex(
        (category) => category._id === newPlace.category
      )

      if (categoryIndex !== -1) {
        const updatedPlaces = [...prevPlaces]
        updatedPlaces[categoryIndex].places.push({
          placeName: newPlace.placeName,
          status: 1,
        })
        return updatedPlaces
      }
      return prevPlaces
    })
  }

  const handleShowEditModal = (place) => {
    console.log(place)
    setPlaceToEdit(place)
    setShowEditModal(true)
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setPlaceToEdit(null)
  }

  const handleUpdatePlace = (updatedPlace) => {
    setPlaces((prevPlaces) => {
      const oldCategoryIndex = prevPlaces.findIndex(
        (category) => category._id === placeToEdit.category
      )
      const newCategoryIndex = prevPlaces.findIndex(
        (category) => category._id === updatedPlace.category
      )
      const updatedPlaces = [...prevPlaces]
      if (oldCategoryIndex !== -1) {
        updatedPlaces[oldCategoryIndex].places = updatedPlaces[
          oldCategoryIndex
        ].places.filter((place) => place._id !== updatedPlace._id)
      }
      if (newCategoryIndex !== -1) {
        updatedPlaces[newCategoryIndex].places.push(updatedPlace)
      } else {
        updatedPlaces.push({
          _id: updatedPlace.category,
          places: [updatedPlace],
        })
      }

      return updatedPlaces
    })

    setShowEditModal(false)
  }

  const handleReturnHome = () => {
    navigate('/main/dashboard')
  }

  return (
    <Layout title={'Place'}>
      <div className='pt-3 mx-3'>
        <div className='text-end mb-3'>
          <button
            type='button'
            className='btn btn-outline-success btn-sm me-4'
            onClick={handleShowCreateModal}
          >
            <i className='bi bi-plus-circle'></i> Place
          </button>

          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={handleReturnHome}
          >
            <i className='bi bi-house-fill'></i>
          </button>
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'>Place</th>
                <th scope='col'>Status</th>
                <th scope='col'></th>
              </tr>
            </thead>
            <tbody>
              {places.map((category) => (
                <React.Fragment key={category._id}>
                  <tr>
                    <td
                      colSpan={3}
                      className='text-center'
                      style={{ color: '#556B2F', fontWeight: 'bold' }}
                    >
                      {category._id === 1
                        ? 'Book Propagation Places'
                        : category._id === 2
                        ? 'Tamu Places'
                        : ''}
                    </td>
                  </tr>
                  {category.places.map((place, index) => (
                    <tr key={index}>
                      <td>{place.placeName}</td>
                      <td
                        style={{
                          color: place.status === 1 ? 'green' : 'grey',
                        }}
                      >
                        {place.status === 1 ? 'Active' : 'Inactive'}
                      </td>
                      <td className='text-end'>
                        <button
                          type='button'
                          className='btn btn-outline-primary btn-sm'
                          onClick={() => handleShowEditModal(place)}
                        >
                          <i class='bi bi-pencil-square'></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <Modal show={showCreateModal} onHide={handleCloseCreateModal}>
        <CreateNewPlace
          onClose={handleCloseCreateModal}
          onAddPlace={handleAddPlace}
        />
      </Modal>

      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <EditPlace
          onClose={handleCloseEditModal}
          onUpdatePlace={handleUpdatePlace}
          place={placeToEdit}
        />
      </Modal>
    </Layout>
  )
}

export default MainPlaceList
