import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useAuth } from '../../../context/auth'
import { toast } from 'react-toastify'

const ResponseList = () => {
  const navigate = useNavigate()
  const [lists, setLists] = useState([])
  const [auth] = useAuth()

  useEffect(() => {
    const fetchList = async () => {
      try {
        const { data } = await axios.get('/api/v1/user/response/lists')
        setLists(data)
      } catch (error) {
        console.log('Failed to fetch lists:', error)
      }
    }

    fetchList()
  }, [])

  const handleNewRespons = () => {
    navigate('new')
  }

  const handleDelete = async (id) => {
    if (window.confirm('Confirm Delete?')) {
      try {
        await axios.delete(`/api/v1/user/response/delete/${id}`)
        setLists((prevLists) => prevLists.filter((item) => item._id !== id))
        toast.success('Deleted successfully')
      } catch (error) {
        console.error('Failed to delete response:', error)
        toast.error('Failed to delete.')
      }
    }
  }

  const handleReturnHome = () => {
    navigate('/user/dashboard')
  }

  const handleEdit = (id) => {
    navigate(`edit/${id}`)
  }

  return (
    <Layout title={'Rekod Kad Respon'}>
      <div className='pt-3 mx-3'>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <h5 className='mb-0' style={{ color: '#556B2F' }}>
            Rekod Kad Respon
          </h5>
          <div>
            <button
              type='button'
              className='btn btn-outline-success btn-sm me-4'
              onClick={handleNewRespons}
            >
              <i className='bi bi-plus-circle'></i> Kad Respon
            </button>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm'
              onClick={handleReturnHome}
            >
              <i className='bi bi-house-fill'></i>
            </button>
          </div>
        </div>

        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>ID</th>
              <th scope='col'>Date</th>
              <th scope='col'>Name</th>
              <th scope='col'>Race</th>
              <th scope='col'>Lang.</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {lists.length > 0 ? (
              lists.map((item) => (
                <React.Fragment key={item._id}>
                  <tr>
                    <td>{item._id.slice(0, 6)}</td>
                    <td>
                      {new Date(item.submitDate).toLocaleDateString('en-GB', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })}
                    </td>
                    <td
                      className='fw-bold'
                      style={{
                        color: item.gender === 1 ? '#2f4483' : '#78003c',
                      }}
                    >
                      {item.name}
                    </td>
                    <td>{item.race}</td>
                    <td>
                      {(() => {
                        switch (item.language) {
                          case 1:
                            return 'BM'
                          case 2:
                            return 'C'
                          case 3:
                            return 'E'
                          case 4:
                            return 'Other'
                          default:
                            return ''
                        }
                      })()}
                    </td>
                    <td className='text-end'>
                      <button
                        type='button'
                        className='btn btn-outline-primary btn-sm'
                        onClick={() => handleEdit(item._id)}
                      >
                        <i class='bi bi-pencil-square'></i>
                      </button>
                      <button
                        type='button'
                        className='btn btn-outline-danger btn-sm ms-3'
                        onClick={() => handleDelete(item._id)}
                      >
                        <i className='bi bi-trash-fill'></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan='6'>
                      <div className='mb-1'>
                        <i
                          className='bi bi-house-fill me-2'
                          style={{ color: '#556B2F' }}
                        ></i>
                        {item.address || '-'}
                      </div>
                      <div className='mb-1'>
                        <i
                          className='bi bi-telephone-fill me-2'
                          style={{ color: '#556B2F' }}
                        ></i>
                        {item.phone || '-'}
                      </div>
                      <div className='mb-1'>
                        <i
                          className='bi bi-book me-2'
                          style={{ color: '#556B2F' }}
                        ></i>
                        {item.bookRecord.length > 0
                          ? item.bookRecord.map((book, index) => (
                              <span key={index}>
                                {book.description} - {book.qty}
                              </span>
                            ))
                          : '-'}
                      </div>
                      <div>
                        <i
                          className='bi bi-card-list me-2'
                          style={{ color: '#556B2F' }}
                        ></i>
                        {item.remark || '-'}
                      </div>
                      <div>
                        {item.responseOption &&
                          item.responseOption.length > 0 && (
                            <div>
                              {item.responseOption.map((option, index) => {
                                let optionLabel = ''
                                switch (option) {
                                  case 1:
                                    optionLabel = 'Kunjungan Rumah'
                                    break
                                  case 2:
                                    optionLabel = 'Pameran Buku'
                                    break
                                  case 3:
                                    optionLabel = 'Persidangan Khas'
                                    break
                                  case 4:
                                    optionLabel = 'Tempahan Buku'
                                    break
                                  default:
                                    optionLabel = ''
                                }
                                return (
                                  <span key={index}>
                                    <i className='bi bi-check'></i>
                                    {optionLabel}
                                    {index < item.responseOption.length - 1 &&
                                      ', '}
                                  </span>
                                )
                              })}
                            </div>
                          )}
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan='9' className='text-center'>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default ResponseList
