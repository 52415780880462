import React, { useState, useEffect } from 'react'
import axios from 'axios'

const EditPlace = ({ onClose, onUpdatePlace, place }) => {
  const [placeName, setPlaceName] = useState(place?.placeName || '')
  const [category, setCategory] = useState(place?.category || 1)
  const [loading, setLoading] = useState(false)

  const handleSave = async () => {
    if (!placeName) {
      alert('Place name is required.')
      return
    }
    setLoading(true)
    try {
      const response = await axios.put(`/api/v1/main/place/edit/${place._id}`, {
        category,
        placeName,
      })
      onUpdatePlace(response.data)
      onClose()
    } catch (error) {
      console.error('Failed to update place:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (place) {
      setPlaceName(place.placeName)
      setCategory(place.category)
    }
  }, [place])

  return (
    <>
      <div className='modal-header'>
        <h5 className='modal-title' style={{ fontSize: '17px' }}>
          Edit Place
        </h5>
        <button type='button' className='btn-close' onClick={onClose}></button>
      </div>
      <div className='modal-body'>
        <div className='mb-3'>
          <label className='mb-2'>Category:</label>
          <div>
            <label className='me-3'>
              <input
                type='radio'
                value={1}
                checked={category === 1}
                onChange={() => setCategory(1)}
                style={{ marginRight: '8px' }}
              />
              Book Propagation
            </label>
            <label>
              <input
                type='radio'
                value={2}
                checked={category === 2}
                onChange={() => setCategory(2)}
                style={{ marginRight: '8px' }}
              />
              Tamu
            </label>
          </div>
        </div>
        <div className='mb-3'>
          <label className='mb-2'>Place Name:</label>
          <input
            type='text'
            className='form-control'
            value={placeName}
            onChange={(e) => setPlaceName(e.target.value)}
          />
        </div>
      </div>
      <div className='modal-footer'>
        <button
          className='btn btn-outline-primary btn-sm me-3'
          onClick={handleSave}
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Submit'}
        </button>
        <button className='btn btn-outline-secondary btn-sm' onClick={onClose}>
          Cancel
        </button>
      </div>
    </>
  )
}

export default EditPlace
