import React, { useEffect, useState } from 'react'
import axios from 'axios'

const AdminEditUser = ({ userId, onSuccess, onCancel }) => {
  const [name, setName] = useState('')
  const [username, setUsername] = useState('')
  const [role, setRole] = useState(1)
  const [status, setStatus] = useState(1)
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [groups, setGroups] = useState([])
  const [teams, setTeams] = useState([])
  const [selectedGroup, setSelectedGroup] = useState('')
  const [selectedTeam, setSelectedTeam] = useState('')

  useEffect(() => {
    fetchUserDetails()
    fetchGroups()
  }, [userId])

  const fetchUserDetails = async () => {
    try {
      const { data } = await axios.get(`/api/v1/admin/user/${userId}`)
      setName(data.user.name)
      setUsername(data.user.username)
      setRole(data.user.role)
      setStatus(data.user.status)
      setSelectedGroup(data.user.groupId?._id || '')
      setSelectedTeam(data.user.teamId?._id || '')
      if (data.user.groupId?._id) fetchTeams(data.user.groupId._id)
    } catch (error) {
      console.error('Error fetching user details:', error)
    }
  }

  const fetchGroups = async () => {
    try {
      const { data } = await axios.get('/api/v1/admin/user/groups')
      setGroups(data.groups)
    } catch (error) {
      console.error('Error fetching groups:', error)
    }
  }

  const fetchTeams = async (groupId) => {
    try {
      const { data } = await axios.get(
        `/api/v1/admin/user/groups/${groupId}/teams`
      )
      setTeams(data.teams)
    } catch (error) {
      console.error('Error fetching teams:', error)
    }
  }

  const handleGroupChange = (e) => {
    const groupId = e.target.value
    setSelectedGroup(groupId)
    setSelectedTeam('')
    fetchTeams(groupId)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const payload = {
        name,
        username,
        role,
        status,
        groupId: selectedGroup || undefined,
        teamId: selectedTeam || undefined,
      }
      // Include password only if it's not empty
      if (password.trim()) {
        payload.password = password
      }

      const { data } = await axios.put(`/api/v1/admin/user/${userId}`, payload)

      if (data?.success) {
        if (onSuccess) onSuccess()
      } else {
        console.error(data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <div className='modal-header'>
        <h5 className='modal-title'>Edit User</h5>
        <button type='button' className='btn-close' onClick={onCancel}></button>
      </div>
      <div className='modal-body'>
        <form onSubmit={handleSubmit}>
          <div className='mb-3'>
            <label className='mb-2 text-muted'>Name</label>
            <input
              type='text'
              className='form-control'
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className='mb-3'>
            <label className='mb-2 text-muted'>Username</label>
            <input
              type='text'
              className='form-control'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className='mb-3'>
            <label className='mb-2 text-muted'>Role</label>
            <select
              className='form-select'
              value={role}
              onChange={(e) => setRole(Number(e.target.value))}
            >
              <option value={1}>Admin</option>
              <option value={2}>Main Coordinator</option>
              <option value={3}>Coordinator</option>
            </select>
          </div>
          {role === 2 || role === 3 ? (
            <div className='mb-3'>
              <label className='mb-2 text-muted'>Group</label>
              <select
                className='form-select'
                value={selectedGroup}
                onChange={handleGroupChange}
              >
                <option value=''>Select Group</option>
                {groups.map((group) => (
                  <option key={group._id} value={group._id}>
                    {group.groupName}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
          {role === 3 ? (
            <div className='mb-3'>
              <label className='mb-2 text-muted'>Team</label>
              <select
                className='form-select'
                value={selectedTeam}
                onChange={(e) => setSelectedTeam(e.target.value)}
                disabled={!selectedGroup}
              >
                <option value=''>Select Team</option>
                {teams.map((team) => (
                  <option key={team._id} value={team._id}>
                    {team.teamName}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
          <div className='mb-3'>
            <label className='mb-2 text-muted'>Status</label>
            <div>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='status'
                  value={1}
                  checked={status === 1}
                  onChange={() => setStatus(1)}
                />
                <label className='form-check-label'>Active</label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='status'
                  value={2}
                  checked={status === 2}
                  onChange={() => setStatus(2)}
                />
                <label className='form-check-label'>Inactive</label>
              </div>
            </div>
          </div>
          <div className='mb-3'>
            <label className='mb-2 text-muted'>Password</label>
            <div className='input-group'>
              <input
                type={showPassword ? 'text' : 'password'}
                className='form-control'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type='button'
                className='btn btn-outline-secondary'
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? 'Hide' : 'Show'}
              </button>
            </div>
            <small className='text-muted'>
              Leave blank to keep the current password
            </small>
          </div>

          <div className='text-end'>
            <button
              type='submit'
              className='btn btn-outline-warning me-4 btn-sm'
            >
              Update
            </button>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm'
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default AdminEditUser
