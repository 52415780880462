import { Routes, Route } from 'react-router-dom'
import PrivateRoute from './components/Routes/PrivateRoute'
import PageNotFound from './pages/PageNotFound'
import HomePage from './pages/HomePage'
import Login from './pages/Auth/Login'
import AdminRoute from './components/Routes/AdminRoute'
import UserRoute from './components/Routes/UserRoute'
import AdminDashboard from './pages/Admin/Dashboard'
import AdminUsersList from './pages/Admin/Users/UserList'
import AdminGroupList from './pages/Admin/Groups/GroupList'
import AdminBookStockList from './pages/Admin/BookStock/BookStockList'
import MainCoordinatorRoute from './components/Routes/MainCoordinatorRoute'
import MainCoordinatorDashboard from './pages/MainCoordinator/Dashboard'
import MainPackingListDetails from './pages/MainCoordinator/PackingList/PackingListDetails'
import UserDashboard from './pages/User/Dashboard'
import ResponseList from './pages/User/Respon/ResponseList'
import ResponseCard from './pages/User/Respon/ResponseCard'
import ResponseCardEdit from './pages/User/Respon/ResponseCardEdit'
import SaleRecordList from './pages/User/SaleRecord/SaleRecordList'
import NewSale from './pages/User/SaleRecord/NewSale'
import StatisticList from './pages/User/Statistics/StatisticList'
import NewStatistic from './pages/User/Statistics/NewStatistic'
import EditStatistic from './pages/User/Statistics/EditStatistic'
import AdminPackingListIndex from './pages/Admin/PackingList/PackingListIndex'
import MainPlaceList from './pages/MainCoordinator/Place/PlaceList'

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<PrivateRoute />}>
          <Route path='/' element={<HomePage />} />
        </Route>
        <Route path='/login' element={<Login />} />
        <Route path='*' element={<PageNotFound />} />

        {/* Admin */}
        <Route path='/admin' element={<AdminRoute />}>
          <Route path='dashboard' element={<AdminDashboard />} />
          <Route path='book-stock' element={<AdminBookStockList />} />
          {/* Form 1 - Book Record */}
          <Route path='packing-list' element={<AdminPackingListIndex />} />
          {/* Users */}
          <Route path='users' element={<AdminUsersList />} />
          {/* Group */}
          <Route path='group' element={<AdminGroupList />} />
        </Route>

        {/* Main */}
        <Route path='/main' element={<MainCoordinatorRoute />}>
          <Route path='dashboard' element={<MainCoordinatorDashboard />} />
          {/* Form 1 */}
          <Route path='book-records' element={<MainPackingListDetails />} />
          {/* Place */}
          <Route path='places' element={<MainPlaceList />} />
        </Route>

        {/* User */}
        <Route path='/user' element={<UserRoute />}>
          <Route path='dashboard' element={<UserDashboard />} />
          <Route path='respons' element={<ResponseList />} />
          <Route path='respons/new' element={<ResponseCard />} />
          <Route path='respons/edit/:id' element={<ResponseCardEdit />} />
          <Route path='sales-record' element={<SaleRecordList />} />
          <Route path='sales-record/new' element={<NewSale />} />
          <Route path='statistics' element={<StatisticList />} />
          <Route path='statistics/new' element={<NewStatistic />} />
          <Route path='statistics/edit/:id' element={<EditStatistic />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
