import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'

const SaleRecordList = () => {
  const navigate = useNavigate()
  const [lists, setLists] = useState([])
  const [editingRow, setEditingRow] = useState(null) // To track the row being edited
  const [editingField, setEditingField] = useState(null) // To track the field being edited
  const [editedValue, setEditedValue] = useState('')

  useEffect(() => {
    const fetchList = async () => {
      try {
        const { data } = await axios.get('/api/v1/user/sale-record/lists')
        setLists(data)
      } catch (error) {
        console.log('Failed to fetch lists:', error)
      }
    }

    fetchList()
  }, [])

  const handleNewSale = () => {
    navigate('new')
  }

  const handleReturnHome = () => {
    navigate('/user/dashboard')
  }

  const handleDelete = async (id) => {
    if (window.confirm('Confirm Delete?')) {
      try {
        await axios.delete(`/api/v1/user/sale-record/delete/${id}`)
        setLists((prevLists) => prevLists.filter((item) => item._id !== id))
        toast.success('Deleted successfully')
      } catch (error) {
        console.error('Failed to delete response:', error)
        toast.error('Failed to delete.')
      }
    }
  }

  const handleDoubleClick = (id, field, value) => {
    setEditingRow(id)
    setEditingField(field)
    setEditedValue(value)
  }

  const handleSave = async (id) => {
    try {
      const originalItem = lists.find((item) => item._id === id)

      const isValueChanged =
        (editingField === 'recordDate' &&
          originalItem.recordDate !== editedValue) ||
        (editingField === 'qty' && originalItem.qty !== Number(editedValue))

      if (!isValueChanged) {
        setEditingRow(null)
        setEditingField(null)
        setEditedValue('')
        return
      }

      const updateData =
        editingField === 'recordDate'
          ? { recordDate: editedValue }
          : { qty: Number(editedValue) }

      await axios.put(`/api/v1/user/sale-record/update/${id}`, updateData)

      setLists((prevLists) =>
        prevLists.map((item) =>
          item._id === id ? { ...item, ...updateData } : item
        )
      )
      toast.success('Updated successfully')
    } catch (error) {
      console.error('Failed to update record:', error)
      toast.error('Failed to update')
    }
    setEditingRow(null)
    setEditingField(null)
    setEditedValue('')
  }

  return (
    <Layout title={'Book Sales Record'}>
      <div className='pt-3 mx-3'>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <h5 className='mb-0' style={{ color: '#556B2F' }}>
            Book Sales Record
          </h5>
          <div>
            <button
              type='button'
              className='btn btn-outline-success btn-sm me-4'
              onClick={handleNewSale}
            >
              <i className='bi bi-plus-circle'></i> Book Sales
            </button>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm'
              onClick={handleReturnHome}
            >
              <i className='bi bi-house-fill'></i>
            </button>
          </div>
        </div>

        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>Date</th>
              <th scope='col'>Book</th>
              <th scope='col' className='text-end'>
                Price
              </th>
              <th scope='col' className='text-center'>
                Qty
              </th>
              <th scope='col' className='text-end'>
                Total Amount
              </th>
              <th className='text-end'>Kad Response</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {lists.length > 0 ? (
              lists.map((item) => (
                <tr key={item._id}>
                  <td
                    onDoubleClick={() =>
                      handleDoubleClick(
                        item._id,
                        'recordDate',
                        new Date(item.recordDate).toISOString().split('T')[0]
                      )
                    }
                  >
                    {editingRow === item._id &&
                    editingField === 'recordDate' ? (
                      <input
                        type='date'
                        value={editedValue}
                        onChange={(e) => setEditedValue(e.target.value)}
                        onBlur={() => handleSave(item._id)}
                        onKeyDown={(e) =>
                          e.key === 'Enter' && handleSave(item._id)
                        }
                        className='form-control form-control-sm'
                        autoFocus
                      />
                    ) : (
                      new Date(item.recordDate).toLocaleDateString('en-GB', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })
                    )}
                  </td>
                  <td>{item.description}</td>
                  <td className='text-end'>{item.price.toFixed(2)}</td>
                  <td
                    onDoubleClick={() =>
                      handleDoubleClick(item._id, 'qty', item.qty)
                    }
                    className='text-center'
                  >
                    {editingRow === item._id && editingField === 'qty' ? (
                      <input
                        type='number'
                        value={editedValue}
                        onChange={(e) => setEditedValue(e.target.value)}
                        onBlur={() => handleSave(item._id)}
                        onKeyDown={(e) =>
                          e.key === 'Enter' && handleSave(item._id)
                        }
                        className='form-control form-control-sm'
                        ref={(input) => input && input.select()}
                        autoFocus
                      />
                    ) : (
                      item.qty
                    )}
                  </td>
                  <td className='text-end'>
                    {(item.price * item.qty).toFixed(2)}
                  </td>
                  <td className='text-end'>
                    {item.contactData ? (
                      <Link
                        to={'/user/respons'}
                        className='text-decoration-none'
                      >
                        {item.contactData.slice(0, 6)}
                      </Link>
                    ) : null}
                  </td>
                  <td className='text-end'>
                    {!item.contactData && (
                      <button
                        type='button'
                        className='btn btn-outline-danger btn-sm'
                        onClick={() => handleDelete(item._id)}
                      >
                        <i className='bi bi-trash-fill'></i>
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan='9' className='text-center'>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default SaleRecordList
